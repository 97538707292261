import React from "react";
import { Link } from "gatsby";
import { Typography, Button, Row, Col, Tooltip } from "antd";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

const ButtonNextPrevious = ({
  previousUrl,
  previousText,
  nextUrl,
  nextText,
}) => {
  return (
    <>
      <Row align="center">
        <Col span="22">
          <div style={{ textAlign: "center", marginBottom: "16px" }}>
            <CaretLeftOutlined
              style={{ color: "rgba(0,0,0,0.25)", marginRight: "12px" }}
            />
            <Text style={{ color: "rgba(0,0,0,0.25)" }}>Navigatie</Text>
            <CaretRightOutlined
              style={{ color: "rgba(0,0,0,0.25)", marginLeft: "12px" }}
            />
          </div>
        </Col>
        <Col span="22">
          {previousUrl ? (
            <Link to={previousUrl} className={"nextBtn"}>
              <Tooltip title="Vorige pagina" placement="bottomLeft">
                <Button
                  ghost
                  size="large"
                  type="primary"
                  className="button-prev"
                >
                  <LeftOutlined />
                  {previousText}
                </Button>
              </Tooltip>
            </Link>
          ) : (
            <Button
              ghost
              size="large"
              disabled
              type="primary"
              className="button-prev"
            >
              <LeftOutlined />
            </Button>
          )}
          {nextUrl ? (
            <Link to={nextUrl} className={"nextBtn"}>
              <Tooltip title="Volgende pagina" placement="topRight">
                <Button
                  ghost
                  size="large"
                  type="primary"
                  className="button-next"
                >
                  {nextText}
                  <RightOutlined />
                </Button>
              </Tooltip>
            </Link>
          ) : (
            <Button
              ghost
              size="large"
              disabled
              type="primary"
              className="button-next"
            >
              <RightOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ButtonNextPrevious;
