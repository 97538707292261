import React from "react";
import { StaticQuery, graphql } from "gatsby";
import config from "../../config";
import ContactFormRating from "./ContactFormRating";
import ContactFormRatingDrawer from "./ContactFormRatingDrawer";

import { Typography, Badge, Divider } from "antd";
const { Text } = Typography;

// eslint-disable-next-line no-unused-vars
const ListItem = ({ className, active, level, ...props }) => {
  const customClass = active ? "active" : "";
  return (
    <li
      className={customClass}
      style={{ lineHeight: "4px", marginTop: "12px", marginBottom: "12px" }}
    >
      <a href={props.to} {...props} />
    </li>
  );
};

const SidebarLayout = ({ date, location, currentPath }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
              }
              tableOfContents
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      let finalNavItems;
      if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
        const navItems = allMdx.edges.map((item, index) => {
          let innerItems;
          if (item !== undefined) {
            if (
              item.node.fields.slug === location ||
              config.gatsby.pathPrefix + item.node.fields.slug === location
            ) {
              if (item.node.tableOfContents.items) {
                innerItems = item.node.tableOfContents.items.map(
                  (innerItem, index) => {
                    return (
                      <ListItem
                        key={index}
                        to={`#${innerItem.title}`}
                        level={1}
                        style={{ textDecoration: "underline" }}
                      >
                        {innerItem.title}
                      </ListItem>
                    );
                  }
                );
              }
            }
          }
          if (innerItems) {
            finalNavItems = innerItems;
          }
        });
      }

      if (finalNavItems && finalNavItems.length && currentPath != "") {
        return (
          <div
            style={{
              paddingLeft: "0px",
              paddingTop: "25vh",
              paddingBottom: "32px",
            }}
          >
            <Badge status="default" text="Inhoud" />
            <ul>{finalNavItems}</ul>
            <Divider />
            <Badge status="default" text="Laatste wijziging" />
            <br />
            <Text
              style={{ fontSize: "11px", color: "gray", marginLeft: "15px" }}
            >
              {date}
            </Text>
            <Divider />
            <Badge status="default" text="Waardeer deze pagina" />
            <br />
            <ContactFormRatingDrawer currentPath={currentPath} />
            <Divider />
          </div>
        );
      } else {
        return (
          <div
            style={{
              paddingLeft: "0px",
              paddingTop: "25vh",
              paddingBottom: "32px",
            }}
          >
            <Badge status="default" text="Laatste wijziging" />
            <br />
            <Text
              style={{ fontSize: "11px", color: "gray", marginLeft: "15px" }}
            >
              {date}
            </Text>
            <Divider />
            <Badge status="default" text="Waardeer deze pagina" />
            <br />
            <ContactFormRatingDrawer currentPath={currentPath} />
            <Divider />
          </div>
        );
      }
    }}
  />
);

export default SidebarLayout;
