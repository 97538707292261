import React from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
const Breadcrumbs = ({ parent, title }) => {
  return (
    <>
      {title !== "Welkom!" ? (
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item href="/">
            <HomeOutlined />
          </Breadcrumb.Item>
          {parent !== "" ? (
            <Breadcrumb.Item className="breadcrumb-item">
              {parent}
            </Breadcrumb.Item>
          ) : null}
          <Breadcrumb.Item> </Breadcrumb.Item>
        </Breadcrumb>
      ) : null}
    </>
  );
};

export default Breadcrumbs;
