import React from "react";
import Search from "./Search";
import { useStaticQuery, graphql } from "gatsby";

function SearchBox() {
  const data = useStaticQuery(graphql`
    query SearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `);

  return (
    <>
      <Search searchIndex={data.siteSearchIndex.index} />
    </>
  );
}

export default SearchBox;
