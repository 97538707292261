import React from "react";
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms";
import { useFormik } from "formik";
import { Alert, Button, Divider, notification } from "antd";
import { SendOutlined } from "@ant-design/icons";

const ContactFormRating = ({ rating, setShowDrawer, currentPath }) => {
  const netlify = useNetlifyForm({
    name: "Rating submissions",
    action: "/thanks",
    honeypotName: "bot-field",
    onSuccess: (response, context) => {
      setShowDrawer(false);
      notification.open({
        message: "Bedankt!",
        duration: 3,
        type: "success",
        description: "Uw waardering is vezonden",
      });
    },
  });
  const { handleSubmit, handleChange, handleBlur, touched, errors, values } =
    useFormik({
      initialValues: {
        email: "",
        message: "",
        rating: rating,
        name: "",
        url: currentPath,
      },
      onSubmit: (values) => {
        netlify.handleSubmit(null, values);
      },
      validate: (values) => {
        const errors = {};
        if (!values.acceptTerms) {
          errors.acceptTerms = "Het accepteren van de voorwaarden is verplicht";
        }
        return errors;
      },
    });

  return (
    <NetlifyFormProvider {...netlify}>
      <NetlifyFormComponent onSubmit={handleSubmit}>
        <Honeypot />
        <div>
          <label
            htmlFor="message"
            style={{
              display: "block",
              fontSize: "14px",
              marginBottom: "8px",
            }}
          >
            Uw toelichting (optioneel):
          </label>
          <textarea
            type="text"
            name="message"
            id="message"
            rows="4"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
            className="ant-input"
          />
        </div>
        <div>
          <label
            htmlFor="email"
            style={{
              display: "block",
              fontSize: "14px",
              marginBottom: "8px",
              marginTop: "16px",
            }}
          >
            Uw e-mailadres (optioneel):
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            className="ant-input"
          />
        </div>
        <div>
          <label
            htmlFor="name"
            style={{
              display: "block",
              fontSize: "14px",
              marginBottom: "8px",
              marginTop: "16px",
            }}
          >
            Uw naam (optioneel):
          </label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            className="ant-input"
          />
        </div>

        <div>
          <input
            type="number"
            hidden
            name="rating"
            id="rating"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.rating}
            className="ant-input"
          />
          <input
            type="text"
            hidden
            name="url"
            id="url"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.url}
            className="ant-input"
          />
        </div>
        <Divider />
        <div>
          <label
            htmlFor="acceptTerms"
            style={{
              display: "block",
              fontSize: "14px",
              marginTop: "16px",
              marginBottom: "8px",
            }}
          >
            <input
              name="acceptTerms"
              type="checkbox"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.acceptTerms}
              style={{ marginRight: "8px" }}
            />{" "}
            Ik begrijp dat de door mij verstrekte persoonsgegevens ten behoeve
            van het contactformulier op lta-antistollingszorg.nl vertrouwelijk
            worden behandeld. Gegevens worden zonder toestemming niet verstrekt
            aan derden.
          </label>
          {errors.acceptTerms ? (
            <Alert
              message={errors.acceptTerms}
              type="warning"
              showIcon
              style={{ marginTop: "8px" }}
            />
          ) : null}
        </div>
        {netlify.error && (
          <Alert
            message="Er gaat helaas wat fout met het versturen van uw waardering. Probeer het later nog eens!"
            type="error"
            showIcon
          />
        )}
        <div>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!values.acceptTerms}
            style={{ marginTop: "16px" }}
            icon={<SendOutlined />}
            size="large"
          >
            Versturen
          </Button>
        </div>
      </NetlifyFormComponent>
    </NetlifyFormProvider>
  );
};

export default ContactFormRating;
