import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Card, Row, Col, Tag, Space } from "antd";

const Footer = ({ currentPath }) => (
  <StaticQuery
    query={graphql`
      query footerQuery {
        site {
          siteMetadata {
            versionNumber
          }
        }
      }
    `}
    render={(data) => {
      const {
        site: {
          siteMetadata: { versionNumber },
        },
      } = data;

      return (
        <footer className="footer">
          {currentPath !== "" && (
            <Row align="center" justify="middle" style={{ marginTop: "24px" }}>
              <Col span={3}>
                <Card
                  size="small"
                  bodyStyle={{ padding: "0", height: "0px" }}
                  cover={
                    <img
                      alt="Nederlandse Internisten Vereniging"
                      src="/img/logo-niv.png"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                        padding: "10%",
                        backgroundColor: "#fafafa",
                      }}
                    />
                  }
                ></Card>
              </Col>
              <Col span={3}>
                <Card
                  size="small"
                  bodyStyle={{ padding: "0", height: "0px" }}
                  cover={
                    <img
                      alt="Kennisinstituut van de Federatie Medisch Specialisten"
                      src="/img/logo-fms.png"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                        padding: "10%",
                        backgroundColor: "#fafafa",
                      }}
                    />
                  }
                ></Card>
              </Col>
              <Col span={3}>
                <Card
                  size="small"
                  bodyStyle={{ padding: "0", height: "0px" }}
                  cover={
                    <img
                      alt="Nederlandse Vereniging voor Cardiologie"
                      src="/img/logo-nvvc.png"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                        padding: "10%",
                        backgroundColor: "#fafafa",
                      }}
                    />
                  }
                ></Card>
              </Col>
              <Col span={3}>
                <Card
                  size="small"
                  bodyStyle={{ padding: "0", height: "0px" }}
                  cover={
                    <img
                      alt="Nederlands Huisartsen Genootschap"
                      src="/img/logo-nhg.png"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                        padding: "10%",
                        backgroundColor: "#fafafa",
                      }}
                    />
                  }
                ></Card>
              </Col>
              <Col span={3}>
                <Card
                  size="small"
                  bodyStyle={{ padding: "0", height: "0px" }}
                  cover={
                    <img
                      alt="Harteraad"
                      src="/img/logo-harteraad.png"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                        padding: "20%",
                        backgroundColor: "#fafafa",
                      }}
                    />
                  }
                ></Card>
              </Col>
            </Row>
          )}
          <Row span="22" align="center" style={{ marginTop: "48px" }}>
            <Col>
              {currentPath !== "" && (
                <Tag
                  color="#09c"
                  style={{ color: "white" }}
                  className="hide-for-small"
                >
                  Landelijke transmurale afspraak (LTA) Antistollingszorg
                </Tag>
              )}
              <Link to="/changelog" style={{ color: "gray" }}>
                <Tag>versie: {versionNumber}</Tag>
              </Link>
            </Col>
          </Row>
        </footer>
      );
    }}
  />
);

export default Footer;
