import React from "react";
import { Drawer, Typography } from "antd";
import MenuLeft from "./MenuLeft";
import { MenuFoldOutlined } from "@ant-design/icons";

const { Text } = Typography;

class MobileDrawer extends React.Component {
  constructor() {
    super();
    this.state = { visible: false };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Text onClick={this.showDrawer}>
          Menu <MenuFoldOutlined style={{ marginLeft: "5px" }} />
        </Text>
        <Drawer
          title="Menu"
          placement="right"
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ padding: "0", backgroundColor: "#EDEDED" }}
          style={{ zIndex: "6000" }}
          width="256"
          className="mobile-drawer"
        >
          <MenuLeft onClose={this.onClose} />
        </Drawer>
      </>
    );
  }
}

export default MobileDrawer;
