import React, { useState, useEffect } from "react";
import { StaticQuery, graphql, navigate, Link } from "gatsby";
import { Menu } from "antd";
import {
  FormOutlined,
  CommentOutlined,
  CalculatorOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

const MenuLeft = ({ onClose }) => (
  <StaticQuery
    query={graphql`
      query menuQuery {
        site {
          siteMetadata {
            headerTitle
          }
        }
      }
    `}
    render={(data) => {
      const rootSubmenuKeys = [
        "voorschrijven-en-follow-up",
        "communicatie-met-patienten",
        "periprocedureel-beleid",
        "bloedingen-onder-antitrombotica",
      ];

      const {
        site: {
          siteMetadata: { headerTitle },
        },
      } = data;

      const currentPath =
        typeof window !== "undefined"
          ? window.location.pathname.replace("/", "")
          : "/";

      const currentPathSplit =
        typeof window !== "undefined"
          ? window.location.pathname.split(";")[0].split("/")
          : null;

      const [openKeys, setOpenKeys] = useState([]);

      useEffect(() => {
        if (currentPathSplit) {
          setOpenKeys(currentPathSplit);
        }
      }, []);

      const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          setOpenKeys(keys);
        } else {
          setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
      };

      return (
        <>
          <Menu
            style={{ width: 256 }}
            selectedKeys={[currentPath]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            mode="inline"
          >
            <div
              className="menu-title"
              onClick={() => navigate("/", { replace: true })}
            >
              <span
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  letterSpacing: "1px",
                }}
              >
                {headerTitle}
              </span>
            </div>
            <Menu.Item key="inleiding">
              <Link onClick={onClose} to="/inleiding">
                Inleiding
              </Link>
            </Menu.Item>
            <Menu.Item key="afbakening">
              <Link onClick={onClose} to="/afbakening">
                Afbakening
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <SubMenu
              key="voorschrijven-en-follow-up"
              icon={<FormOutlined />}
              title="Voorschrijven en follow-up"
            >
              <Menu.Item key="voorschrijven-en-follow-up/algemene-informatie">
                <Link
                  onClick={onClose}
                  to="/voorschrijven-en-follow-up/algemene-informatie"
                >
                  Algemene informatie
                </Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.ItemGroup
                key="voorschrijven-en-follow-up/doac"
                title="DOAC"
              >
                <Menu.Item key="voorschrijven-en-follow-up/doac/overzicht">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/doac/overzicht"
                  >
                    Overzicht
                  </Link>
                </Menu.Item>
                <Menu.Item key="voorschrijven-en-follow-up/doac/follow-up-controles">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/doac/follow-up-controles"
                  >
                    Follow-up controles
                  </Link>
                </Menu.Item>
                <Menu.Divider />
              </Menu.ItemGroup>
              <Menu.ItemGroup key="voorschrijven-en-follow-up/vka" title="VKA">
                <Menu.Item key="voorschrijven-en-follow-up/vka/overzicht">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/vka/overzicht"
                  >
                    Overzicht
                  </Link>
                </Menu.Item>
                <Menu.Item key="voorschrijven-en-follow-up/vka/follow-up-controles">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/vka/follow-up-controles"
                  >
                    Follow-up controles
                  </Link>
                </Menu.Item>
                <Menu.Divider />
              </Menu.ItemGroup>
              <Menu.ItemGroup key="voorschrijven-en-follow-up/tar" title="TAR">
                <Menu.Item key="voorschrijven-en-follow-up/tar/overzicht">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/tar/overzicht"
                  >
                    Overzicht
                  </Link>
                </Menu.Item>
                <Menu.Item key="voorschrijven-en-follow-up/tar/follow-up-controles">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/tar/follow-up-controles"
                  >
                    Follow-up controles
                  </Link>
                </Menu.Item>
                <Menu.Divider />
              </Menu.ItemGroup>
              <Menu.ItemGroup
                key="voorschrijven-en-follow-up/lmwh"
                title="LMWH"
              >
                <Menu.Item key="voorschrijven-en-follow-up/lmwh/overzicht">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/lmwh/overzicht"
                  >
                    Overzicht LMWH
                  </Link>
                </Menu.Item>
                <Menu.Item key="voorschrijven-en-follow-up/lmwh/dosering-lmwh-bij-verminderde-nierfunctie">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/lmwh/dosering-lmwh-bij-verminderde-nierfunctie"
                  >
                    Dosering LWMH bij verminderde nierfunctie
                  </Link>
                </Menu.Item>
                <Menu.Item key="voorschrijven-en-follow-up/lmwh/laag-complexe-antistolling">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/lmwh/laag-complexe-antistolling"
                  >
                    Laag complexe antistolling
                  </Link>
                </Menu.Item>
                <Menu.Item key="voorschrijven-en-follow-up/lmwh/hoog-complexe-antistolling">
                  <Link
                    onClick={onClose}
                    to="/voorschrijven-en-follow-up/lmwh/hoog-complexe-antistolling"
                  >
                    Hoog complexe antistolling
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.Divider />
            </SubMenu>
            <SubMenu
              key="communicatie-met-patienten"
              icon={<CommentOutlined />}
              title="Communicatie met patienten"
            >
              <Menu.Item key="communicatie-met-patienten/algemene-informatie">
                <Link
                  onClick={onClose}
                  to="/communicatie-met-patienten/algemene-informatie"
                >
                  Algemene informatie
                </Link>
              </Menu.Item>
              <Menu.Item key="communicatie-met-patienten/gezamenlijke-besluitvorming">
                <Link
                  onClick={onClose}
                  to="/communicatie-met-patienten/gezamenlijke-besluitvorming"
                >
                  Gezamenlijke besluitvorming
                </Link>
              </Menu.Item>
              <Menu.Item key="communicatie-met-patienten/therapietrouw">
                <Link
                  onClick={onClose}
                  to="/communicatie-met-patienten/therapietrouw"
                >
                  Therapietrouw
                </Link>
              </Menu.Item>
              <Menu.Item key="communicatie-met-patienten/informatievoorziening">
                <Link
                  onClick={onClose}
                  to="/communicatie-met-patienten/informatievoorziening"
                >
                  Informatievoorziening
                </Link>
              </Menu.Item>
              <Menu.Item key="communicatie-met-patienten/veelgestelde-vragen-over-doacs">
                <Link
                  onClick={onClose}
                  to="/communicatie-met-patienten/veelgestelde-vragen-over-doacs"
                >
                  Veelgestelde vragen DOACs
                </Link>
              </Menu.Item>
              <Menu.Item key="communicatie-met-patienten/veelgestelde-vragen-over-vka">
                <Link
                  onClick={onClose}
                  to="/communicatie-met-patienten/veelgestelde-vragen-over-vka"
                >
                  Veelgestelde vragen VKA
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="periprocedureel-beleid"
              icon={<ApartmentOutlined />}
              title="Periprocedureel beleid"
            >
              <Menu.Item key="periprocedureel-beleid/algemene-informatie">
                <Link
                  onClick={onClose}
                  to="/periprocedureel-beleid/algemene-informatie"
                >
                  Algemene informatie
                </Link>
              </Menu.Item>
              <Menu.Item key="periprocedureel-beleid/overzicht-trombo-embolie-risico">
                <Link
                  onClick={onClose}
                  to="/periprocedureel-beleid/overzicht-trombo-embolie-risico"
                >
                  Overzicht trombo-embolie risico
                </Link>
              </Menu.Item>
              <Menu.Item key="periprocedureel-beleid/overzicht-periprocedureel-bloedingsrisico">
                <Link
                  onClick={onClose}
                  to="/periprocedureel-beleid/overzicht-periprocedureel-bloedingsrisico"
                >
                  Overzicht periprocedureel bloedingsrisico
                </Link>
              </Menu.Item>
              <Menu.Item key="periprocedureel-beleid/extra-opmerkingen-tandheelkundige-ingrepen">
                <Link
                  onClick={onClose}
                  to="/periprocedureel-beleid/extra-opmerkingen-tandheelkundige-ingrepen"
                >
                  Extra opmerkingen tandheelkundige ingrepen
                </Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.ItemGroup key="periprocedureel-beleid/doac" title="DOAC">
                <Menu.Item key="periprocedureel-beleid/doac/beslisboom-dabigatran">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/doac/beslisboom-dabigatran"
                  >
                    Beslisboom Dabigatran
                  </Link>
                </Menu.Item>
                <Menu.Item key="periprocedureel-beleid/doac/beslisboom-apixaban">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/doac/beslisboom-apixaban"
                  >
                    Beslisboom Apixaban
                  </Link>
                </Menu.Item>
                <Menu.Item key="periprocedureel-beleid/doac/beslisboom-rivaroxaban">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/doac/beslisboom-rivaroxaban"
                  >
                    Beslisboom Rivaroxaban
                  </Link>
                </Menu.Item>
                <Menu.Item key="periprocedureel-beleid/doac/beslisboom-edoxaban">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/doac/beslisboom-edoxaban"
                  >
                    Beslisboom Edoxaban
                  </Link>
                </Menu.Item>
                <Menu.Divider />
              </Menu.ItemGroup>
              <Menu.ItemGroup key="periprocedureel-beleid/vka" title="VKA">
                <Menu.Item key="periprocedureel-beleid/vka/beslisboom-vka">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/vka/beslisboom-vka"
                  >
                    Beslisboom VKA
                  </Link>
                </Menu.Item>
                <Menu.Item key="periprocedureel-beleid/vka/praktische-aanbeveling">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/vka/praktische-aanbeveling"
                  >
                    Praktische aanbeveling
                  </Link>
                </Menu.Item>
                <Menu.Divider />
              </Menu.ItemGroup>
              <Menu.ItemGroup key="periprocedureel-beleid/tar" title="TAR">
                <Menu.Item key="periprocedureel-beleid/tar/dual-antiplatelet-therapy-dapt-behandeling">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/tar/dual-antiplatelet-therapy-dapt-behandeling"
                  >
                    Dual Antiplatelet Therapy (DAPT) behandeling
                  </Link>
                </Menu.Item>
                <Menu.Item key="periprocedureel-beleid/tar/minimale-duur-onderbreken-en-herstarten-dapt">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/tar/minimale-duur-onderbreken-en-herstarten-dapt"
                  >
                    Minimale duur onderbreken en herstarten DAPT
                  </Link>
                </Menu.Item>
                <Menu.Item key="periprocedureel-beleid/tar/beslisboom-monotherapie-clopidogrel-of-asa">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/tar/beslisboom-monotherapie-clopidogrel-of-asa"
                  >
                    Beslisboom monotherapie Clopidogrel of ASA
                  </Link>
                </Menu.Item>
                <Menu.Item key="periprocedureel-beleid/tar/beslisboom-dapt-asa-en-dipyridamol">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/tar/beslisboom-dapt-asa-en-dipyridamol"
                  >
                    Beslisboom DAPT ASA en dipyridamol
                  </Link>
                </Menu.Item>
                <Menu.Item key="periprocedureel-beleid/tar/beslisboom-dapt-asa-en-prasugrel-clopidogrel-ticagrelor">
                  <Link
                    onClick={onClose}
                    to="/periprocedureel-beleid/tar/beslisboom-dapt-asa-en-prasugrel-clopidogrel-ticagrelor"
                  >
                    Beslisboom DAPT ASA en prasugrel, clopidogrel, ticagrelor
                  </Link>
                </Menu.Item>
                <Menu.Divider />
              </Menu.ItemGroup>
            </SubMenu>
            <SubMenu
              key="bloedingen-onder-antitrombotica"
              title="Bloedingen onder antitrombotica"
              icon={<CalculatorOutlined />}
            >
              <Menu.ItemGroup
                key="bloedingen-onder-antitrombotica/ernstige-bloedingen"
                title="Ernstige bloedingen"
              >
                <Menu.Item key="bloedingen-onder-antitrombotica/ernstige-bloedingen/algemene-informatie">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/ernstige-bloedingen/algemene-informatie"
                  >
                    Algemene informatie
                  </Link>
                </Menu.Item>
                <Menu.Item key="bloedingen-onder-antitrombotica/ernstige-bloedingen/beslisboom-doac-dabigatran">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/ernstige-bloedingen/beslisboom-doac-dabigatran"
                  >
                    Beslisboom DOAC - dabigatran
                  </Link>
                </Menu.Item>
                <Menu.Item key="bloedingen-onder-antitrombotica/ernstige-bloedingen/beslisboom-doac-factor-xa-remmers-rivaroxaban-apixaban-edoxaban">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/ernstige-bloedingen/beslisboom-doac-factor-xa-remmers-rivaroxaban-apixaban-edoxaban"
                  >
                    Beslisboom DOAC - factor Xa-remmers, Rivaroxaban, Apixaban,
                    Edoxaban
                  </Link>
                </Menu.Item>
                <Menu.Item key="bloedingen-onder-antitrombotica/ernstige-bloedingen/beslisboom-vka">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/ernstige-bloedingen/beslisboom-vka"
                  >
                    Beslisboom VKA
                  </Link>
                </Menu.Item>
                <Menu.Item key="bloedingen-onder-antitrombotica/ernstige-bloedingen/beslisboom-tar">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/ernstige-bloedingen/beslisboom-tar"
                  >
                    Beslisboom TAR
                  </Link>
                </Menu.Item>
                <Menu.Divider />
              </Menu.ItemGroup>
              <Menu.ItemGroup
                key="bloedingen-onder-antitrombotica/niet-ernstige-bloedingen"
                title="Niet-ernstige bloedingen"
              >
                <Menu.Item key="bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/algemene-informatie">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/algemene-informatie"
                  >
                    Algemene informatie
                  </Link>
                </Menu.Item>
                <Menu.Item key="bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/beslisboom-beleid-bij-bloedingen-voor-de-huisarts">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/beslisboom-beleid-bij-bloedingen-voor-de-huisarts"
                  >
                    Beslisboom Beleid voor de huisarts
                  </Link>
                </Menu.Item>
                <Menu.Item key="bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/bloeding-onder-lmwh-ongefractioneerde-heparine">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/bloeding-onder-lmwh-ongefractioneerde-heparine"
                  >
                    LMWH/ ongefractioneerde heparine
                  </Link>
                </Menu.Item>
                <Menu.Item key="bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/beslisboom-doac">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/beslisboom-doac"
                  >
                    Beslisboom DOAC
                  </Link>
                </Menu.Item>
                <Menu.Item key="bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/beslisboom-vka">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/beslisboom-vka"
                  >
                    Beslisboom VKA
                  </Link>
                </Menu.Item>
                <Menu.Item key="bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/beslisboom-tar">
                  <Link
                    onClick={onClose}
                    to="/bloedingen-onder-antitrombotica/niet-ernstige-bloedingen/beslisboom-tar"
                  >
                    Beslisboom TAR
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
            <Menu.Divider />
            <Menu.Item key="literatuur">
              <Link onClick={onClose} to="/literatuur">
                Literatuur
              </Link>
            </Menu.Item>
            <Menu.Item key="colofon/afkortingenlijst">
              <Link onClick={onClose} to="/colofon/afkortingenlijst">
                Afkortingenlijst
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="colofon">
              <Link onClick={onClose} to="/colofon">
                Colofon
              </Link>
            </Menu.Item>
            <Menu.Item key="colofon/verantwoording">
              <Link onClick={onClose} to="/colofon/verantwoording">
                Verantwoording
              </Link>
            </Menu.Item>
            <Menu.Item key="contact">
              <Link onClick={onClose} to="/contact">
                Contact
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="changelog">
              <Link onClick={onClose} to="/changelog">
                Changelog
              </Link>
            </Menu.Item>
          </Menu>
        </>
      );
    }}
  />
);

export default MenuLeft;
