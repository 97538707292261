import React, { Component } from "react";
import { Index } from "elasticlunr";
import { Link } from "gatsby";
import { Input, Typography, List } from "antd";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Text } = Typography;

export default class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      query: ``,
      results: [],
    };
  }

  render() {
    return (
      <div>
        {this.state.results.length ? (
          <>
            {this.state.results.length ? (
              <>
                <List size="small" bordered className="search-results">
                  {this.state.results.map((page) => (
                    <List.Item key={page.id} style={{}}>
                      <Link
                        to={page.path}
                        onClick={this.onResultClick}
                        style={{ display: "block" }}
                      >
                        <Text type="secondary" style={{ marginRight: "0px" }}>
                          {page.parent} /{" "}
                        </Text>
                        {page.title}
                      </Link>
                    </List.Item>
                  ))}
                </List>
                <div
                  className="search-background"
                  onClick={this.clearResults}
                ></div>
              </>
            ) : (
              <Text
                type="secondary"
                style={{
                  paddingLeft: "14px",
                  paddingTop: "7px",
                  fontWeight: "bold",
                }}
              >
                Geen resultaten
              </Text>
            )}
          </>
        ) : (
          <div />
        )}
        <Search
          ref="SearchBox"
          value={this.state.query}
          onChange={this.search}
          prefix={
            <SearchOutlined
              style={{ color: "rgba(0,0,0,.25)", margin: "0 10px" }}
            />
          }
          allowClear
          style={{ width: "100%", marginLeft: "0px" }}
          enterButton="Zoeken"
          autoFocus
          size="large"
          placeholder="Type uw zoekterm"
          className="search-box"
        />
      </div>
    );
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex);

  search = (evt) => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    });
  };

  onResultClick = (evt) => {
    const query = "";
    this.index = this.getOrCreateIndex();
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    });
  };

  clearResults = () => {
    const query = "";
    this.index = this.getOrCreateIndex();
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    });
  };
}

Search.propTypes = {
  searchIndex: PropTypes.object,
};
