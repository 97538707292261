import React, { useState } from "react";
import ContactFormRating from "./ContactFormRating";
import { Drawer, Rate, Typography } from "antd";

const { Paragraph } = Typography;

const ContactFormRatingDrawer = ({ currentPath }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [rating, setRating] = useState(0);

  return (
    <>
      <Paragraph>
        <Rate
          onChange={(value) => {
            setShowDrawer(true);
            setRating(value);
          }}
          value={rating}
        />
      </Paragraph>
      <Drawer
        drawerStyle={{ backgroundColor: "white" }}
        footer={null}
        onCancel={() => {
          setShowDrawer(false);
          setRating(0);
        }}
        onClose={() => {
          setShowDrawer(false);
          setRating(0);
        }}
        title={
          <>
            Waardering indienen <Rate disabled value={rating} />
          </>
        }
        visible={showDrawer}
        style={{ zIndex: "6000" }}
        destroyOnClose="true"
      >
        <ContactFormRating
          rating={rating}
          setShowDrawer={setShowDrawer}
          currentPath={currentPath}
        />
      </Drawer>
    </>
  );
};

export default ContactFormRatingDrawer;
