import React from "react";
import { StaticQuery, graphql, navigate } from "gatsby";
import { PageHeader } from "antd";
import MobileDrawer from "./mobileDrawer";

const HeaderMobile = () => (
  <StaticQuery
    query={graphql`
      query headerMobileQuery {
        site {
          siteMetadata {
            headerTitle
          }
        }
      }
    `}
    render={(data) => {
      const {
        site: {
          siteMetadata: { headerTitle },
        },
      } = data;

      return (
        <PageHeader
          extra={[
            <>
              <MobileDrawer />
            </>,
          ]}
          title={
            <span style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
              {headerTitle}
            </span>
          }
          style={{
            backgroundColor: "#fff",
            width: "100%",
            borderBottom: "2px solid #ede7f3",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "5000",
            padding: "9px 20px",
          }}
          className={"show-for-mobile"}
        />
      );
    }}
  />
);

export default HeaderMobile;
