import React, { Component } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import Layout from "../components/layout";
import Breadcrumbs from "../components/breadcrumbs";
import config from "../../config";
import { Typography, Divider, Row, Col } from "antd";
import SearchBox from "../components/SearchBox";
import "../components/styles.css";
import FormContact from "../components/FormContact";
import SidebarRightItems from "../components/sidebarRightItems";
import Footer from "../components/Footer";
import MenuLeft from "../components/MenuLeft";
import ButtonNextPrevious from "../components/ButtonNextPrevious";
import HeaderMobile from "../components/HeaderMobile";
const { Title } = Typography;

export default class MDXRuntimeTest extends Component {
  render() {
    const { data } = this.props;

    if (!data) {
      return this.props.children;
    }
    const { mdx } = data;

    const location = typeof window !== "undefined" && window.location.pathname;
    const currentPath =
      typeof window !== "undefined"
        ? window.location.pathname.replace("/", "")
        : "/";

    // meta tags
    let canonicalUrl = config.gatsby.siteUrl;
    canonicalUrl =
      config.gatsby.pathPrefix !== "/"
        ? canonicalUrl + config.gatsby.pathPrefix
        : canonicalUrl;
    canonicalUrl = canonicalUrl + mdx.fields.slug;

    return (
      <Layout
        {...this.props}
        title={mdx.fields.title}
        date={mdx.frontmatter.date}
      >
        <Helmet>
          <title>{mdx.fields.title} | LTA Antistollingszorg</title>
          <meta name="title" content={mdx.fields.title} />
          <meta
            name="description"
            content="De landelijke transmurale afspraak (LTA) antistollingszorg geeft richting aan de samenwerking tussen diverse specialismen. Op deze website vindt u onder meer informatie over het voorschrijven en de follow-up van antitrombotica, periprocedureel beleid en het beleid bij bloedingen. Ook vindt u op deze website informatie over onderwerpen die belangrijk zijn in de communicatie met patiënten. Voor de onderdelen periprocedureel beleid en beleid bij bloedingen zijn beslisbomen ontwikkeld."
          />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <HeaderMobile title={mdx.fields.title} date={mdx.frontmatter.date} />

        <div className="wrapper">
          <aside className="sidebar-left">
            <MenuLeft />
          </aside>
          <div className="wrapper-content">
            <main>
              <Row align="center">
                <Col span="20">
                  <SearchBox />
                </Col>
              </Row>
              <div className="wrapper-page-content">
                <Breadcrumbs
                  parent={mdx.frontmatter.parent}
                  title={mdx.fields.title}
                />
                <Typography>
                  <Title
                    level={1}
                    style={{
                      fontSize: "42px",
                      color: "#0099cc",
                      marginBottom: "10px",
                    }}
                  >
                    {mdx.fields.title}
                  </Title>
                </Typography>
                <Divider />
                <MDXRenderer>{mdx.body}</MDXRenderer>
                <Divider />
                <ButtonNextPrevious
                  nextUrl={mdx.frontmatter.nextUrl}
                  nextText={mdx.frontmatter.nextText}
                  previousUrl={mdx.frontmatter.previousUrl}
                  previousText={mdx.frontmatter.previousText}
                />
              </div>
              <Divider />
            </main>
            <Footer currentPath={currentPath} />
          </div>
          <aside className="sidebar-right">
            <SidebarRightItems
              date={mdx.frontmatter.date}
              location={location}
              currentPath={currentPath}
            />
          </aside>
          <FormContact />
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        parent
        date
        nextUrl
        nextText
        previousUrl
        previousText
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;
